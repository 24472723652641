'use client'
import React, { FC, useEffect, useState } from 'react'
import cx from 'clsx'
import SafeLink from '@/components/atoms/safe-link'
import { resolveURL } from '@/lib/routes'
import { MenuItem } from './menu.interface'
import { prepareNavSections } from './prepare-nav-sections'

interface Props {
  items: MenuItem[]
}

export const FlyoutNavigation: FC<Props> = ({ items }) => {
  if (!items?.length) return null
  const columns = prepareNavSections(items)

  return (
    <div className="grid grid-flow-col-dense gap-x-6 mt-3">
      {columns.map((c, i) => (
        <div key={i}>
          {c.sections.map((s) => (
            <div key={s.title}>
              <h3 className="strech align-top uppercase">{s.title}</h3>
              <ul role="list" className="space-y-6 mb-6">
                {s.items.map((item, i) => (
                  <li key={`${item.url}--${i}`} className="flow-root">
                    <SafeLink
                      href={resolveURL(item) ?? '/'}
                      className="-m-3 flex items-center rounded-md py-1 px-3 text-base font-medium"
                    >
                      {/* <item.icon className="h-6 w-6 flex-shrink-0 text-gray-400" aria-hidden="true" /> */}
                      <span className="ml-4 group ">
                        {item.title}
                        <div className="w-0 group-hover:w-full h-[3px] bg-primary transition-sizes duration-100" />
                      </span>
                    </SafeLink>
                  </li>
                ))}
              </ul>
            </div>
          ))}
        </div>
      ))}
    </div>
  )
}

interface GenericFlyoutMenuProps {
  open: boolean
  items?: MenuItem[]
  children?: React.ReactNode
}

const GenericFlyoutMenu: FC<GenericFlyoutMenuProps> = ({ open, items, children }) => {
  const [firstLoadShow, setFirstLoadShow] = useState(true)
  // hide flyout menu animation on first load
  useEffect(() => {
    if (open && firstLoadShow) {
      setFirstLoadShow(false)
    }
  }, [open, firstLoadShow])

  return (
    <div
      className={cx(
        'absolute inset-x-0 z-[5]  transform lg:block bg-white rounded-b-2xl overflow-hidden top-10 transition-shadow duration-500',
        open ? 'animate-motionOpenFast lg:animate-motionOpen max-h-max shadow-lg' : 'animate-motionClose max-h-0',
        { hidden: firstLoadShow },
      )}
    >
      <div className="container lg:max-w-md">
        <div className={cx('relative mx-auto grid max-w-7xl', { 'pt-5': items?.length })}>
          <div className="grid gap-y-4 px-4 py-8 max-lg:w-full max-lg:pt-0 lg: sm:gap-x-8 sm:py-2 sm:px-6 xl:pr-12 lg:justify-center justify-self-start">
            {children}
            <div className="max-lg:hidden">
              <FlyoutNavigation items={items ?? []} />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default GenericFlyoutMenu
