interface Obj {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [key: string]: any
}

export const isEmptyObject = (obj: Obj) => !obj || Object.keys(obj).length === 0

export const isUndefined = (value: unknown): value is undefined => {
  return typeof value === 'undefined'
}
