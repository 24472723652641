import { FC, ReactNode } from 'react'
import clsx from 'clsx'
import SafeLink from '@/components/atoms/safe-link'

interface IconLinkProps {
  icon: ReactNode
  showTitle?: boolean
  title?: string
  url: string
  className?: string
}

const IconLink: FC<IconLinkProps> = ({ icon, url, showTitle, title, className }) => {
  return (
    <SafeLink
      title={title}
      href={url}
      className={clsx('flex text-header-social hover:text-header-social-hover leading-6', className)}
      target="_blank"
      rel="noreferrer"
    >
      {icon}
      <span className={clsx(!showTitle && 'sr-only', showTitle && 'ml-1')}>{title}</span>
    </SafeLink>
  )
}

export default IconLink
