import { FC } from 'react'
import cx from 'clsx'
import SafeLink from '@/components/atoms/safe-link'

interface NavigationItemProps {
  title: string
  href?: string | null
  onClick?: () => void
  isActive?: boolean
  className?: string
  newTab?: boolean
}

export const NavigationItem: FC<NavigationItemProps> = ({ title, href, onClick, className, newTab }) => {
  if (!href?.length && !onClick) return null

  return href ? (
    <SafeLink id={title} href={href} className={className} target={newTab ? '_blank' : undefined}>
      <span className={cx(className, 'text-base font-semibold text-text hover:text-primary')}>{title}</span>
    </SafeLink>
  ) : (
    <span
      onClick={onClick}
      className={cx(className, 'text-base font-semibold text-text hover:text-primary hover:cursor-pointer')}
    >
      {title}
    </span>
  )
}
