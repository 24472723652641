import { FC } from 'react'
import clsx from 'clsx'

interface DividerProps {
  className?: string
}

const Divider: FC<DividerProps> = ({ className }) => {
  return (
    <div className={clsx('relative', className)}>
      <div className="absolute inset-0 flex items-center" aria-hidden="true">
        <div className="w-full border-t-2 border-white" />
      </div>
    </div>
  )
}

export default Divider
