'use client'

import React, { FC } from 'react'
import Image from 'next/image'
import Link from 'next/link'
import { usePathname } from 'next/navigation'
import { ImageAsset } from '@/types/site.interface'
import { handleHomeLocale } from '@/utils.ts/constants'
import { nextSanityImage } from '../elements/image'

interface SiteLogoInterface {
  siteLogo?: ImageAsset
  siteName?: string
  className?: string
}

const Wrapper = ({ children, pathname }) =>
  pathname === '/' ? (
    <button aria-label="Go Home" onClick={() => window.scrollTo(0, 0)}>
      {children}
    </button>
  ) : (
    <Link href={handleHomeLocale(pathname)} scroll={false} aria-label="Go Home">
      {children}
    </Link>
  )

// TODO: Version 5.0.0 of next-sanity-image library has removed support for the blur options.
// Remove when migration to next-sanity-image is done
// const nextSanityImageOptions: UseNextSanityImageOptions = {
//   enableBlurUp: false,
// }

export const SiteLogo: FC<SiteLogoInterface> = ({ siteLogo, siteName = 'Your Site' }) => {
  const pathname = usePathname()
  if (!siteLogo) return null
  // const img = nextSanityImage(siteLogo, nextSanityImageOptions)
  const img = nextSanityImage(siteLogo)

  const aspectRatio = img.width / img.height

  const sizesString = `
    (min-width: 640px) ${Math.ceil(80 * aspectRatio)}px,
    (min-width: 768px) ${Math.ceil(90 * aspectRatio)}px,
    (min-width: 1024px) ${Math.ceil(90 * aspectRatio)}px,
    (min-width: 1280px) ${Math.ceil(100 * aspectRatio)}px,
    (min-width: 1536px) ${Math.ceil(200 * aspectRatio)}px,
    ${Math.ceil(50 * aspectRatio)}px
    `
  return (
    <Wrapper pathname={pathname}>
      <span className="sr-only">{siteName}</span>
      {img.src && (
        <Image
          {...img}
          sizes={sizesString}
          alt={siteLogo?.alt || siteName}
          className="h-12 w-auto sm:h-16 object-contain"
          priority={true}
        />
      )}
    </Wrapper>
  )
}
