import { RefObject, useEffect } from 'react'

export const useOutsideClick = <T extends HTMLElement>(ref: RefObject<T>, onClickOut: () => void, deps: unknown) => {
  useEffect(() => {
    if (!deps) return
    const onClick = ({ target }: MouseEvent) => {
      if (ref.current && !(ref.current as HTMLElement).contains(target as Node)) {
        onClickOut?.()
      }
    }

    document.addEventListener('click', onClick)
    return () => document.removeEventListener('click', onClick)
  }, [ref, onClickOut, deps])

  return null
}

export default useOutsideClick
