import fastClean from 'fast-clean'
import { defaultLocale, locales } from '@/translations/supported-languages'
import { NextParams } from '@/types/site.interface'
import { ensureLeadingSlash, removeLeadingSlash } from '@ignition/library/utils/urls'

export const REGEX = {
  paginatedSlug: /\/page\/\d+$/,
}

interface Subject {
  [key: string]: unknown
}
/**
 * @param subject
 * @param options: fastClean.ICleanerOptions
 * @returns
 */
export const fastCleaner = (subject: Subject, options?: fastClean.ICleanerOptions) =>
  fastClean.clean(subject, {
    nullCleaner: false,
    emptyArraysCleaner: false,
    emptyObjectsCleaner: false,
    emptyStringsCleaner: false,
    ...options,
  })

/**
 *  Get slug from params and making sure it has a leading slash
 * @param params
 * @returns
 */

export const slugParamsCleaner = (params?: NextParams) => {
  let slug = params?.slug
  const rawSlug = Array.isArray(slug) ? slug.join('/') : slug || '/'
  slug = rawSlug.replace(REGEX.paginatedSlug, '')
  return ensureLeadingSlash(slug)
}

/**
 *  Get current page locale from params
 * @param params
 * @returns
 */

export const localeParamsCleaner = (params?: NextParams) => {
  const slug = params?.slug
  if (Array.isArray(slug) && slug.length > 0) {
    // filter empty strings
    const slugFiltered = slug.filter((s) => s)
    const currentLocale = slugFiltered[0] ? locales.find((locale) => slugFiltered[0] === locale) : defaultLocale
    return currentLocale || defaultLocale
  }
  return defaultLocale
}

export const cutLocaleFromSlug = (slug: string) => {
  if (!slug) return { locale: defaultLocale, cleanedSlug: '/' }
  const splittedSLug = removeLeadingSlash(slug).split('/')
  const locale = localeParamsCleaner({ slug: splittedSLug })
  const slugCutLocale = slug.replace(`/${locale}/`, '/')

  return { locale, cleanedSlug: slugCutLocale }
}

export const handleHomeLocale = (path?: string) => {
  if (!path) return '/'
  const foundLocale = locales.find((locale) => path.includes(locale)) || defaultLocale
  return foundLocale === defaultLocale ? '/' : ensureLeadingSlash(foundLocale)
}

export const localesCacheTag = (slug: string): string[] => {
  const tags: string[] = []
  if (!slug) return tags
  const { cleanedSlug } = cutLocaleFromSlug(slug)

  locales.forEach((locale) => {
    if (locale !== defaultLocale) {
      tags.push(`${ensureLeadingSlash(locale)}${ensureLeadingSlash(cleanedSlug)}`)
    } else {
      tags.push(ensureLeadingSlash(cleanedSlug))
    }
  })
  return tags
}
