import { useEffect, useState } from 'react'

const getHeight = () => {
  const { body, documentElement } = document
  return Math.max(
    body.scrollHeight,
    body.offsetHeight,
    documentElement.clientHeight,
    documentElement.scrollHeight,
    documentElement.offsetHeight,
  )
}

const getThreshold = () => {
  const height = getHeight()
  const threshold = Math.ceil(height / 4)
  return threshold
}

const useScrollToTop = () => {
  const [visible, setVisible] = useState(false)

  useEffect(() => {
    const threshold = getThreshold()

    const toggleScrollButtonVisibility = () => {
      if (document.body.scrollTop > threshold || document.documentElement.scrollTop > threshold) {
        setVisible(true)
        document.documentElement.style.scrollBehavior = 'auto'
      } else {
        setVisible(false)
      }
    }

    document.addEventListener(
      'scroll',
      () => {
        toggleScrollButtonVisibility()
      },
      { passive: true },
    )

    document.addEventListener('load', () => {
      toggleScrollButtonVisibility()
    })

    return () => {
      document.removeEventListener('scroll', toggleScrollButtonVisibility)

      document.removeEventListener('load', toggleScrollButtonVisibility)
    }
  }, [visible, setVisible])

  const scrollToTop = () => {
    document.documentElement.style.scrollBehavior = 'smooth'
    document.body.scrollTop = 0
    document.documentElement.scrollTop = 0
  }

  return { visible, scrollToTop }
}

export default useScrollToTop
