'use client'

import React, { FC, useRef, useState } from 'react'
import cx from 'clsx'
import { X } from 'phosphor-react'
import { TextAlignRight } from 'phosphor-react-sc'
import useOutsideClick from '@/hooks/use-outside-click'
import { resolveURL } from '@/lib/routes'
import { ButtonLink, ImageAsset } from '@/types/site.interface'
import { Button } from '../atoms/button'
import { SiteLogo } from '../molecules/site-logo'
import GenericFlyoutMenu from './generic-flyout-menu'
import { GenericHeaderMobile } from './generic-header-mobile'
import { MenuItem, MenuProps } from './menu.interface'
import { NavigationItem } from './navigation-item'

const MobileBurger = ({ open, setFlyoutIsOpen }) => (
  <div className="-mr-2 flex items-center lg:hidden">
    <button
      onClick={() => setFlyoutIsOpen(!open)}
      className="focus-ring-inset inline-flex items-center justify-center rounded-md p-2 text-text hover:text-primary focus:outline-none"
    >
      <span className="sr-only">{!open ? 'Open main menu' : 'Close menu'}</span>
      {!open ? (
        <TextAlignRight className="h-10 w-10 fill-primary" aria-hidden="true" />
      ) : (
        <X className="h-10 w-10 fill-primary text-primary -mr-1" aria-hidden="true" />
      )}
    </button>
  </div>
)

interface Header {
  menuMobilePrimary?: MenuProps
  menuDesktopPrimary?: MenuProps
  navButton?: ButtonLink
  siteLogo?: ImageAsset
  enableShadow?: boolean
  siteName?: string
}

const GenericHeader: FC<Header> = ({
  menuDesktopPrimary,
  menuMobilePrimary,
  navButton,
  siteLogo,
  enableShadow,
  siteName,
}) => {
  const [flyoutIsOpen, setFlyoutIsOpen] = useState<boolean>(false)
  const [flyoutMenu, setFlyoutMenu] = useState<MenuItem[]>()
  const menuRef = useRef<HTMLDivElement>(null)
  useOutsideClick(menuRef, () => setFlyoutIsOpen(false), flyoutIsOpen)

  const resolveMenu = (items?: MenuItem[]) => {
    if (items?.length) {
      setFlyoutMenu(items)
      setFlyoutIsOpen(true)
    }
  }

  return (
    <header className="relative z-10">
      {enableShadow && <div className="pointer-events-none absolute inset-1 z-60 shadow-lg" aria-hidden="true" />}
      <div className="py-4">
        <div
          ref={menuRef}
          className={cx(
            'relative bg-white flex max-w-3xl items-center justify-between px-4 sm:px-6 mx-3 lg:mx-auto transition-rounded duration-700 md:duration-200 rounded-2xl',
          )}
          aria-label="Global"
        >
          <div
            className={cx(
              'flex flex-1 items-center z-[10]',
              !navButton ? 'justify-between xl:justify-start xl:gap-[9.3rem] text-nowrap' : 'justify-between',
            )}
          >
            <div className="flex w-full items-center justify-between lg:w-auto">
              <SiteLogo siteLogo={siteLogo} siteName={siteName} />
              <MobileBurger open={flyoutIsOpen} setFlyoutIsOpen={setFlyoutIsOpen} />
            </div>
            <nav className="hidden space-x-8 lg:flex py-4">
              {menuDesktopPrimary?.items?.map((item, index) => {
                const href = item?.dropdownItems?.length ? null : resolveURL(item)
                return (
                  <NavigationItem
                    key={`k-${index}-${item.title}`}
                    title={item.title}
                    newTab={item.newTab}
                    href={href}
                    onClick={() => resolveMenu(item.dropdownItems)}
                  />
                )
              })}
            </nav>
            <div className="max-lg:hidden">
              {navButton?.title && (
                <Button {...navButton} className="font-semibold text" fullWidth>
                  {navButton?.title}
                </Button>
              )}
            </div>
          </div>
          <GenericFlyoutMenu open={flyoutIsOpen} items={flyoutMenu}>
            {menuMobilePrimary?.items?.length && (
              <GenericHeaderMobile
                menuMobilePrimary={menuMobilePrimary}
                navButton={navButton}
                flyoutIsOpen={flyoutIsOpen}
              />
            )}
          </GenericFlyoutMenu>
        </div>
      </div>
    </header>
  )
}

export default GenericHeader
