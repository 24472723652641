'use client'

import { FC } from 'react'
import IconLink from '@/components/atoms/icon-link'
import ScrollTopButton from '@/components/atoms/scroll-top-button'
import { SocialLink as ISocialLink } from '@/components/elements/social-links.interface'
import { resolveURL } from '@/lib/routes'
import { Footer as IFooter } from '@/types/site.interface'
import Grid from '@ignition/library/components/atoms/grid'
import Divider from '../../atoms/divider'
import SocialLink from '../../atoms/social-link'
import ImageElement from '../../elements/image'
import { NavigationItem } from '../navigation-item'
import FooterColumn from './footer-column'
import FooterMenu from './footer-menu'
const Footer: FC<IFooter> = ({ menuFooter, socialLinks = [], certificates = [], icons = [] }) => {
  return (
    <footer className="relative bg-footer-background" aria-labelledby="footer-heading">
      <h2 id="footer-heading" className="sr-only">
        Footer
      </h2>
      <nav className="relative mx-auto max-w-sm px-4 sm:max-w-lg sm:px-6 lg:max-w-3xl lg:px-8 py-6 lg:py-10 text-footer-text">
        <Grid className="gap-x-[7.5rem]" columns={{ base: 1, sm: 3, lg: 6, xl: 12 }} gap={8}>
          {menuFooter && menuFooter.items?.length ? (
            <Grid.Item colspan={{ base: 1, sm: 3, lg: 6 }}>
              {menuFooter.items.map((item, index) => {
                return item.type === 'none' ? (
                  <FooterMenu key={`k-${index}-${item.id}`} header={item.title} items={item.dropdownItems} />
                ) : (
                  <NavigationItem
                    key={`k-${index}-${item.id}`}
                    title={item.title}
                    newTab={item.newTab}
                    href={resolveURL(item)}
                  />
                )
              })}
            </Grid.Item>
          ) : null}
          {socialLinks?.length || certificates?.length ? (
            <Grid.Item colspan={{ base: 1, sm: 1, lg: 2 }}>
              <FooterColumn
                title="Folge uns auf"
                columns={[{ items: socialLinks }]}
                renderItem={(socialLink: ISocialLink) => (
                  <SocialLink key={socialLink._key} socialLink={socialLink} showTitle />
                )}
                gridColumns={{ base: 1, sm: 1 }}
              />
            </Grid.Item>
          ) : null}
          {certificates?.length ? (
            <Grid.Item colspan={{ base: 1, sm: 2, lg: 4 }}>
              <FooterColumn
                title="Kundenerfahrung"
                columns={[{ items: [{ certificates }] }]}
                renderItem={(item) => (
                  <div className="flex flex-wrap">
                    {item.certificates.map((certificate) => (
                      <ImageElement
                        key={certificate.asset?._ref}
                        imgClassName="max-w-[250px]"
                        image={certificate}
                        className="mr-2 mb-2"
                      />
                    ))}
                  </div>
                )}
                gridColumns={1}
              />
            </Grid.Item>
          ) : null}
          <Grid.Item colspan={{ base: 1, sm: 2, lg: 4 }}> </Grid.Item>
        </Grid>
      </nav>
      {icons?.length ? (
        <>
          <Divider />
          <div className="flex space-x-16 relative mx-auto max-w-3xl justify-center px-4 sm:px-6 py-6 lg:py-10">
            <div className="flex space-x-2">
              {icons.map((icon) => (
                <IconLink
                  key={icon._key}
                  icon={
                    <ImageElement
                      key={icon._key}
                      image={icon.icon}
                      responsive
                      alt={icon.alt || icon.title}
                      imgClassName="max-h-[56px] w-auto"
                    />
                  }
                  url={icon.url}
                  title={icon.title}
                />
              ))}
            </div>
          </div>
        </>
      ) : null}
      <ScrollTopButton />
    </footer>
  )
}

export default Footer
