'use client'

import { FC } from 'react'
import { resolveURL } from '@/lib/routes'
import { MenuItem } from '../menu.interface'
import { NavigationItem } from '../navigation-item'
import { prepareNavSections } from '../prepare-nav-sections'
import FooterColumn from './footer-column'

interface FooterMenuProps {
  header: string
  items?: MenuItem[]
}

const FooterMenu: FC<FooterMenuProps> = ({ header, items = [] }) => {
  const columns = prepareNavSections(items)

  return (
    <FooterColumn
      title={header}
      columns={columns.map((column) => column.sections).flat()}
      gridColumns={{ base: 1, sm: 3 }}
      renderItem={(item: MenuItem) => (
        <NavigationItem className="!font-medium" title={item.title} newTab={item.newTab} href={resolveURL(item)} />
      )}
    />
  )
}

export default FooterMenu
