import { FC } from 'react'
import { ArrowUp } from 'phosphor-react-sc'
import useScrollToTop from '@ignition/library/hooks/use-scroll-to-top'
import { Button } from './button'

const styles = {
  buttonStyle: (visible: boolean) =>
    `fixed bottom-5 right-5 w-10 h-10 p-1 rounded-full text-center ${visible ? 'flex' : 'hidden'}`,
  iconStyle: 'h-7 w-7 mx-auto my-auto',
}

const ScrollTopButton: FC = () => {
  const { visible, scrollToTop } = useScrollToTop()
  return (
    <Button type="button" onClick={scrollToTop} className={styles.buttonStyle(visible)}>
      <ArrowUp className={styles.iconStyle} weight="bold" aria-hidden="true" />
    </Button>
  )
}
export default ScrollTopButton
