/* eslint-disable @typescript-eslint/no-explicit-any */
'use client'
import { FC, ReactNode } from 'react'
import cx from 'clsx'
import Grid from '@ignition/library/components/atoms/grid'
import { GridColumns } from '@ignition/library/components/atoms/grid/interface'
import { ResponsiveProp } from '@ignition/library/interface'
import { resolveResponsiveProp } from '@ignition/library/utils/resolve-responsive-prop'

interface FooterColumn {
  items: any[]
  title?: string | null
}

interface FooterColumnProps<T = any> {
  title: string
  columns?: FooterColumn[]
  renderItem: (item: T, i: number) => ReactNode
  gridColumns?: ResponsiveProp<GridColumns>
}

const FooterColumn: FC<FooterColumnProps> = ({ title, columns = [], renderItem, gridColumns = 4 }) => {
  return (
    <Grid columns={gridColumns} gap={8} className="mb-2">
      {columns.map(({ items, title: sectionTitle }, i) => (
        <div key={i} className={cx({ 'mt-5': i !== 0 && !sectionTitle })}>
          <h3
            className={cx(
              resolveResponsiveProp('col-span', !sectionTitle ? 1 : 3),
              'font-semibold text-lg leading-6 text-text mb-5',
            )}
          >
            {i === 0 ? title : sectionTitle}
          </h3>
          <Grid.Item className="flex flex-col col-start-2" colspan={{ base: 1 }}>
            {items?.length &&
              items.map((item, i) => {
                return (
                  <div key={i} className="mb-2">
                    {renderItem(item, i)}
                  </div>
                )
              })}
          </Grid.Item>
        </div>
      ))}
    </Grid>
  )
}

export default FooterColumn
