'use client'
import React, { FC, Fragment, useEffect, useState } from 'react'
import cx from 'clsx'
import { resolveURL } from '@/lib/routes'
import { ButtonLink } from '@/types/site.interface'
import { Button } from '../atoms/button'
import { FlyoutNavigation } from './generic-flyout-menu'
import { MenuItem, MenuProps } from './menu.interface'
import { NavigationItem } from './navigation-item'

interface GenericHeaderMobileProps {
  menuMobilePrimary?: MenuProps
  menuMobileSecondary?: MenuProps
  flyoutMenu?: MenuItem[]
  flyoutIsOpen: boolean
  siteName?: string
  navButton?: ButtonLink
}

export const GenericHeaderMobile: FC<GenericHeaderMobileProps> = ({ menuMobilePrimary, navButton, flyoutIsOpen }) => {
  const [menuIndex, setMenuIndex] = useState<number[]>([])

  useEffect(() => {
    if (flyoutIsOpen) {
      setMenuIndex([])
    }
  }, [flyoutIsOpen])

  const resolveMenu = (index: number) => {
    if (menuIndex.includes(index)) {
      setMenuIndex(menuIndex.filter((i) => i !== index))
      return
    }
    setMenuIndex([...menuIndex, index])
  }

  return (
    <Fragment>
      <nav className="mt-6 sm:mt-8 duration-100 transform relative lg:hidden">
        <div className="grid sm:gap-x-4">
          {menuMobilePrimary?.items.map((item, index) => (
            <Fragment key={`k-${index}-${item._key}`}>
              <NavigationItem
                title={item.title}
                newTab={item.newTab}
                href={resolveURL(item)}
                onClick={() => resolveMenu(index)}
                className={cx('py-4')}
              />
              <FlyoutNavigation items={item.dropdownItems ?? []} />
            </Fragment>
          ))}
        </div>
      </nav>
      <div className="mt-8 text-center lg:hidden order-1">
        {navButton?.title && (
          <Button {...navButton} className="font-semibold text" fullWidth>
            {navButton?.title}
          </Button>
        )}
      </div>
    </Fragment>
  )
}
